import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import EventCard from './EventCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
import "./FeaturedEvents.css";
import { getTodayDateString } from './homeBehavior';



function FeaturedEvents() {
  const [featuredEvents, setFeaturedEvents] = useState([]);

  useEffect(() => {
    const fetchFeaturedEvents = async () => {
      const todayString = getTodayDateString();  // Get today's date string in 'YYYY-MM-DD' format
      const eventsQuery = query(
        collection(db, 'events'),
        where('isFeatured', '==', true),
        where('date', '>=', todayString)  // Only get future events
      );

      const eventsCollection = await getDocs(eventsQuery);
      const fetchedEvents = eventsCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Sort the events by date
      const sortedEvents = fetchedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));

      setFeaturedEvents(sortedEvents);
    };

    fetchFeaturedEvents();
  }, []);

  return (
    <div className="featured-events">
      {featuredEvents.length > 0 ? (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          navigation
          pagination={{ clickable: true }}
          autoplay={{ delay: 3000, disableOnInteraction: true }}
          spaceBetween={50}
          slidesPerView={1}
        >
          {featuredEvents.map(event => (
            <SwiperSlide key={event.id}>
              <EventCard event={event} editEvents={false} />
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>No featured events at this time.</p>
      )}
    </div>
  );
}

export default FeaturedEvents;