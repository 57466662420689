// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
import Admin from './components/Admin';
import PrivateRoute from './components/PrivateRoute';
import EventPage from './components/EventPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/login" element={<Login />} />
        <Route path="/event/:eventId" element={<EventPage />} />
        
        {/* Protect the Admin route */}
        <Route
          path="/edit-events"
          element={
            <PrivateRoute>
              <Admin />
            </PrivateRoute>
          }
        />
        
      </Routes>
    </Router>
  );
}

export default App;