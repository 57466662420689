import React, { useEffect, useState } from 'react';
import { collection, getDocs, addDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db, auth } from '../firebase'; // Add auth from firebase
import EventCard from './EventCard';
import FeaturedEvents from './FeaturedEvents';
import './Home.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment'; // For date formatting
import bannerImage from '../images/banner.jpeg';
import profileImage from '../images/riddim-rapids.png';
import { useNavigate } from 'react-router-dom';

import {
  fetchReleases,
  fetchEvents,
  checkAuth,
  handleAddRelease,
  handleDeleteRelease,
  handleEditEvent,
} from './homeBehavior';

function Home() {
  const [releases, setReleases] = useState([]);
  const [events, setEvents] = useState([]);
  const [newRelease, setNewRelease] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);
  const [success, setSuccess] = useState(false);
  const [uploadDate, setUploadDate] = useState(new Date());
  const navigate = useNavigate();
  const [editReleases, setEditReleases] = useState(false);

  useEffect(() => {
    fetchReleases(setReleases);
    fetchEvents(setEvents);
    checkAuth(setUser);
  }, []);

  return (
    <div className="home-container bg-black text-white">
      <div className="container-fluid p-0 mt-4">
        <img src={bannerImage} alt="Banner" className="img-fluid banner-image" />
      </div>

      <div className="profile-image-container">
        <img src={profileImage} alt="Profile" className="profile-image" />
      </div>

      <div className="social-icons d-flex justify-content-center mt-2">
        <a href="https://www.facebook.com/RiddimRapids" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FontAwesomeIcon icon={faFacebookF} size="2x" />
        </a>
        <a href="https://www.instagram.com/riddimrapids/" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FontAwesomeIcon icon={faInstagram} size="2x" />
        </a>
        <a href="https://www.tiktok.com/@riddimrapids" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FontAwesomeIcon icon={faTiktok} size="2x" />
        </a>
        <a href="https://x.com/RiddimRapids" target="_blank" rel="noopener noreferrer" className="mx-2">
          <FontAwesomeIcon icon={faTwitter} size="2x" />
        </a>
      </div>

      {/* Featured Events Section */}
      <div className="featured-events-section container mt-5">
        <h2 className="featured-events-title text-danger">Featured Events</h2>
        <FeaturedEvents />
      </div>

      {/* Upcoming Events Section */}
      <div className="upcoming-events container mt-5">
        <div className="add-event-container">
          <h2 className="text-danger">Upcoming Events</h2>
          {user && (
            <button className="add-event-btn" onClick={() => navigate('/edit-events')}>
              <FontAwesomeIcon icon={faPlus} className="icon" /> Add Event
            </button>
          )}
        </div>
        <div className="row">
          {events.map((event) => (
            <EventCard
              key={event.id}
              event={event}
              editEvents={user}
              handleEdit={() => handleEditEvent(navigate, event)}
            />
          ))}
        </div>
      </div>

      {/* Latest Releases Section */}
      <div className="latest-releases container mt-5">
        <h2 className="text-danger">Latest Releases</h2>

        {user && (
          <>
            <div className="mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Enter SoundCloud link"
                value={newRelease}
                onChange={(e) => setNewRelease(e.target.value)}
              />

              <div className="mt-2">
                <DatePicker
                  selected={uploadDate}
                  onChange={(date) => setUploadDate(date)}
                  className="form-control"
                  placeholderText="Select upload date"
                />
              </div>

              <button
                className="btn btn-primary mt-2"
                onClick={() => handleAddRelease(newRelease, uploadDate, releases, setReleases, setSuccess, setError)}
              >
                Add Release
              </button>

              {success && <div className="alert alert-success mt-2">Release added successfully!</div>}
              {error && <div className="alert alert-danger mt-2">{error}</div>}
            </div>
          </>
        )}

        <div className="row">
          {releases.map((release) => (
            <div key={release.id} className="col-md-4 mb-4 position-relative">
              <h5>{release.title}</h5>
              <iframe
                width="100%"
                height="166"
                scrolling="no"
                frameBorder="no"
                allow="autoplay"
                src={`https://w.soundcloud.com/player/?url=${release.url}&color=%23ff5500&auto_play=false`}
              ></iframe>

              {user && (
                <button
                  className="btn btn-danger position-absolute top-0 end-0"
                  onClick={() => handleDeleteRelease(release.id)}
                  style={{ zIndex: 1 }}
                >
                  X
                </button>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Footer */}
      <footer className="footer" style={{ backgroundColor: '#1c1c1c', color: '#f1f1f1', padding: '20px 0', textAlign: 'center', marginTop: '20px' }}>
        <p style={{ marginBottom: '0' }}>
          Created by <a href="https://jackbald.com" target="_blank" rel="noopener noreferrer" style={{ color: '#00c4cc', textDecoration: 'none', fontWeight: 'bold' }}>Jack Baldwin</a>
        </p>
        <p style={{ fontSize: '12px', marginTop: '5px', color: '#b0b0b0' }}>© 2024 Riddim Rapids. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Home;