// src/components/EventList.js
import React from 'react';

// Function to convert 24-hour (military) time to 12-hour time with AM/PM
const convertTo12Hour = (time) => {
  let [hour, minute] = time.split(':');
  hour = parseInt(hour);
  const ampm = hour >= 12 ? 'PM' : 'AM';
  hour = hour % 12 || 12;  // Convert '0' hour to '12' for 12 AM or 12 PM
  return `${hour}:${minute} ${ampm}`;
};

function EventList({ events, handleEdit, handleDelete }) {
  // Sort the events by date
  const sortedEvents = [...events].sort((a, b) => new Date(a.date) - new Date(b.date));

  return (
    <ul className="list-group">
      {sortedEvents.map((event) => (
        <li key={event.id} className="list-group-item d-flex justify-content-between align-items-center">
          <div>
            <h5>{event.title}</h5>
            <p>
              {event.date} | {convertTo12Hour(event.startTime)} - {convertTo12Hour(event.endTime)}<br />
              {event.venue} | {event.address}
            </p>
            <small>Promoters: {event.promoters}</small><br />
            {event.flyerUrl && (
              <img src={event.flyerUrl} alt={event.title} className="img-thumbnail" style={{ maxWidth: '100px' }} />
            )}
          </div>
          <div>
            <button className="btn btn-warning btn-sm me-2" onClick={() => handleEdit(event)}>Edit</button>
            <button className="btn btn-danger btn-sm" onClick={() => handleDelete(event.id)}>Delete</button>
          </div>
        </li>
      ))}
    </ul>
  );
}

export default EventList;