// src/components/PrivateRoute.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebase';

function PrivateRoute({ children }) {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        navigate('/login'); // Redirect to login if not authenticated
      }
      setLoading(false); // Loading finished
    });

    return () => unsubscribe(); // Cleanup the listener
  }, [navigate]);

  if (loading) return <div>Loading...</div>; // Show loading while checking auth

  return isAuthenticated ? children : null;
}

export default PrivateRoute;