import { collection, getDocs, addDoc, deleteDoc, doc, query, where } from 'firebase/firestore';
import { db, auth } from '../firebase';
import moment from 'moment'; // For date formatting
import { useNavigate } from 'react-router-dom';

// Function to fetch releases
export const fetchReleases = async (setReleases) => {
    const releasesCollection = await getDocs(collection(db, 'releases'));
    const fetchedReleases = releasesCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const sortedReleases = fetchedReleases.sort((a, b) => new Date(b.uploadDate) - new Date(a.uploadDate));
    setReleases(sortedReleases);
};

// Function to fetch events
export const fetchEvents = async (setEvents) => {
    const todayString = getTodayDateString();
    const eventsQuery = query(collection(db, 'events'), where('date', '>=', todayString));
    const eventsCollection = await getDocs(eventsQuery);
    const fetchedEvents = eventsCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

    const sortedEvents = fetchedEvents.sort((a, b) => new Date(a.date) - new Date(b.date));
    setEvents(sortedEvents);
};

// Helper function to get today's date string in 'YYYY-MM-DD' format
export const getTodayDateString = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

// Function to check authentication
export const checkAuth = (setUser) => {
    auth.onAuthStateChanged((user) => {
        setUser(user);
    });
};

// Function to handle adding a new release
export const handleAddRelease = async (newRelease, uploadDate, releases, setReleases, setError, setSuccess) => {
    if (!newRelease) {
        setError('Please enter a valid SoundCloud link');
        return;
    }

    try {
        const response = await fetch(
            `https://soundcloud.com/oembed?format=json&url=${encodeURIComponent(newRelease)}`
        );

        if (!response.ok) {
            throw new Error(`Failed to fetch SoundCloud metadata: ${response.status}`);
        }

        const data = await response.json();
        const localDate = moment(uploadDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

        await addDoc(collection(db, 'releases'), {
            url: newRelease,
            title: data.title,
            author: data.author_name,
            uploadDate: localDate,
        });

        setReleases([{ id: newRelease, url: newRelease, title: data.title, author: data.author_name, uploadDate: localDate }, ...releases]);
        setSuccess(true);
        setError('');
    } catch (err) {
        setError('Failed to add release');
    }
};

// Function to delete a release
export const handleDeleteRelease = async (id, releases, setReleases, setError) => {
    try {
        await deleteDoc(doc(db, 'releases', id));
        setReleases(releases.filter((release) => release.id !== id));
        setError('');
    } catch (err) {
        setError('Failed to delete release');
    }
};

export const handleEditEvent = (navigate, event) => {
    navigate(`/edit-events`, { state: { event } });
};