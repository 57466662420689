import React, { useState, useEffect } from 'react';
import { collection, getDocs, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import EventForm from './EventForm';
import EventList from './EventList';
import { useLocation, useNavigate } from 'react-router-dom';

function Admin() {
  const [events, setEvents] = useState([]);
  const [editing, setEditing] = useState(false);
  const [eventToEdit, setEventToEdit] = useState(null);
  const navigate = useNavigate(); // For navigation back to homepage
  const location = useLocation();

  // Fetch events from Firestore
  useEffect(() => {
    const fetchEvents = async () => {
      const eventsCollection = await getDocs(collection(db, 'events'));
      setEvents(eventsCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
    };
    fetchEvents();
  }, []);

  // Set the event for editing if passed through navigation
  useEffect(() => {
    if (location.state && location.state.event) {
      setEventToEdit(location.state.event);
      setEditing(true);
    }
  }, [location.state]);

  // Handle event editing
  const handleEdit = (event) => {
    setEditing(true);
    setEventToEdit(event);
  };

  // Handle event deletion with confirmation
  const handleDelete = async (id) => {
    const confirmed = window.confirm('Are you sure you want to delete this event?');
    if (confirmed) {
      try {
        await deleteDoc(doc(db, 'events', id));
        setEvents(events.filter((event) => event.id !== id));
      } catch (err) {
      }
    } else {
    }
  };

  // Handle form submission success (update event list without refreshing)
  const handleSubmitSuccess = async () => {
    const eventsCollection = await getDocs(collection(db, 'events'));
    setEvents(eventsCollection.docs.map((doc) => ({ id: doc.id, ...doc.data() })));
  };

  return (
    <div className="container mt-5">
      {/* Return to home button */}
      <button className="btn btn-secondary mb-4" onClick={() => navigate('/')}>
        Return to Home
      </button>

      <div className="row">
        {/* Event form on the left */}
        <div className="col-md-6">
          <h2>{editing ? 'Edit Event' : 'Create a New Event'}</h2>
          <EventForm
            event={eventToEdit}
            editing={editing}
            handleSubmitSuccess={handleSubmitSuccess}
            setEditing={setEditing}
            setEventToEdit={setEventToEdit}
          />
        </div>

        {/* Event list on the right */}
        <div className="col-md-6">
          <h2>Existing Events</h2>
          <EventList events={events} handleEdit={handleEdit} handleDelete={handleDelete} />
        </div>
      </div>
    </div>
  );
}

export default Admin;