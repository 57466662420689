import React, { useState, useEffect } from 'react';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { addDoc, updateDoc, doc, collection, getDocs, getDoc } from 'firebase/firestore';
import { storage, db } from '../firebase';

function EventForm({ event, editing, handleSubmitSuccess, setEditing, setEventToEdit }) {
  const stateAbbreviations = [
    'AL', 'AK', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'FL', 'GA', 'HI', 'ID',
    'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MD', 'MA', 'MI', 'MN', 'MS',
    'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK',
    'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV',
    'WI', 'WY'
  ];

  const [title, setTitle] = useState(event?.title || '');
  const [date, setDate] = useState(event?.date || '');
  const [startTime, setStartTime] = useState(event?.startTime || '');
  const [endTime, setEndTime] = useState(event?.endTime || '');
  const [promoters, setPromoters] = useState(event?.promoters || '');
  const [ageRequirement, setAgeRequirement] = useState(event?.ageRequirement || 'all');
  const [customAge, setCustomAge] = useState(''); // For handling custom age input
  const [facebookEventLink, setFacebookEventLink] = useState(event?.facebookEventLink || '');
  const [ticketLink, setTicketLink] = useState(event?.ticketLink || '');
  const [flyer, setFlyer] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(event?.flyerUrl || '');
  const [imageUrl, setImageUrl] = useState(event?.flyerUrl || '');
  const [error, setError] = useState('');
  const [ticketPrice, setTicketPrice] = useState(event?.ticketPrice || '');
  const [venueOptions, setVenueOptions] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(event?.venueId || '');  // Store the venue ID
  const [venueName, setVenueName] = useState();
  const [venueCity, setVenueCity] = useState();
  const [venueState, setVenueState] = useState('Michigan');

  useEffect(() => {
    const fetchVenues = async () => {
      const venuesCollection = await getDocs(collection(db, 'venues'));
      const venuesList = venuesCollection.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setVenueOptions(venuesList);
    };

    fetchVenues();

    if (event) {
      setTitle(event.title || '');
      setDate(event.date || '');
      setStartTime(event.startTime); // Default to 7 PM
      setEndTime(event.endTime); // Default to 12 AM
      setSelectedVenue(event.venueId || '');  // Ensuring the venue ID is set
      setPromoters(event.promoters || '');
      setImageUrl(event.flyerUrl || '');
      setPreviewUrl(event.flyerUrl || '');
      setAgeRequirement(event.ageRequirement);
      setFacebookEventLink(event.facebookEventLink || '');
      setTicketLink(event.ticketLink || '');
      setTicketPrice(event.ticketPrice || '');
    }
  }, [event]);

  // Handle file input change for the flyer image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFlyer(file);
      setPreviewUrl(URL.createObjectURL(file)); // Preview the selected image
    }
  };

  const addVenue = async (e) => {
    e.preventDefault();
    try {
      // Add new venue to Firestore
      const newVenueRef = await addDoc(collection(db, 'venues'), {
        name: venueName,
        city: venueCity,
        state: venueState
      });
  
      // Fetch the newly added venue from Firestore to get the full venue data
      const newVenueSnapshot = await getDoc(newVenueRef);
      const newVenue = { id: newVenueRef.id, ...newVenueSnapshot.data() };
  
      // Update venue options in the state and select the newly added venue
      setVenueOptions([...venueOptions, newVenue]);
      setSelectedVenue(newVenueRef.id);  // Set the new venue as selected
      console.log('New venue added and selected:', newVenueRef.id);
    } catch (err) {
      setError('Failed to add venue');
    }
  };

  // Handle form submission for creating or editing events
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const finalAgeRequirement = ageRequirement === 'custom' ? customAge : ageRequirement;
      let flyerUrl = imageUrl;
      if (flyer) {
        const flyerRef = ref(storage, `flyers/${flyer.name}`);
        await uploadBytes(flyerRef, flyer);
        flyerUrl = await getDownloadURL(flyerRef);
      }

      const eventData = {
        title,
        date,
        startTime,
        endTime,
        venueId: selectedVenue,  // Store venueId correctly
        promoters,
        ageRequirement: finalAgeRequirement,
        flyerUrl,
        facebookEventLink: facebookEventLink || null, // Optional field
        ticketLink: ticketLink || null, // Optional field
        ticketPrice: ticketLink ? ticketPrice : null, // Only include if there's a ticket link
      };

      if (editing) {
        // If editing, update the event
        const eventDocRef = doc(db, 'events', event.id);
        await updateDoc(eventDocRef, eventData);
      } else {
        // If creating a new event
        await addDoc(collection(db, 'events'), eventData);
      }

      handleSubmitSuccess();
      resetForm();
    } catch (err) {
      setError('Failed to save event');
    }
  };

  // Reset form after submission or cancel
  const resetForm = () => {
    setTitle('');             // Clear the title
    setDate('');              // Clear the date
    setStartTime('');         // Clear the start time
    setEndTime('');           // Clear the end time
    setPromoters('');         // Clear the promoters
    setSelectedVenue('');      // Clear the selected venue
    setAgeRequirement('all'); // Reset age requirement to default
    setFlyer(null);           // Clear the flyer
    setPreviewUrl('');        // Clear the preview URL
    setFacebookEventLink(''); // Clear the Facebook event link
    setTicketLink('');        // Clear the ticket link
    setEditing(false);        // Exit edit mode
    setEventToEdit(null);     // Clear the selected event
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="eventTitle" className="form-label">Event Title:</label>
        <input
          type="text"
          className="form-control"
          id="eventTitle"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="eventDate" className="form-label">Date:</label>
        <input
          type="date"
          className="form-control"
          id="eventDate"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="startTime" className="form-label">Start Time:</label>
        <input
          type="time"
          className="form-control"
          id="startTime"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="endTime" className="form-label">End Time:</label>
        <input
          type="time"
          className="form-control"
          id="endTime"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="venue" className="form-label">Select Venue:</label>
        <select
          id="venue"
          className="form-select"
          value={selectedVenue}
          onChange={(e) => setSelectedVenue(e.target.value)}  // Correctly setting the venue ID
        >
          <option value="">-- Select a Venue --</option>
          {venueOptions.map((venue) => (
            <option key={venue.id} value={venue.id}>
              {venue.name}
            </option>
          ))}
        </select>
        {selectedVenue === '' && (
          <div>
            <input
              type="text"
              className="form-control mt-2"
              placeholder="Enter new venue name"
              value={venueName}
              onChange={(e) => setVenueName(e.target.value)}
            />
            <input
              type="text"
              className="form-control mt-2"
              placeholder="City"
              value={venueCity}
              onChange={(e) => setVenueCity(e.target.value)}
            />
            <label htmlFor="state-select">Choose your state:</label>
            <select id="state-select" value={venueState} onChange={(e) => setVenueState(e.target.value)}>
              {stateAbbreviations.map((stateAbbr) => (
                <option key={stateAbbr} value={stateAbbr}>
                  {stateAbbr}
                </option>
              ))}
            </select>
            <button
              type="submit"
              className="btn btn-primary ms-2"
              onClick={addVenue}
            >
              Add Venue
            </button>
          </div>
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="promoters" className="form-label">Promoters:</label>
        <input
          type="text"
          className="form-control"
          id="promoters"
          value={promoters}
          onChange={(e) => setPromoters(e.target.value)}
          required
        />
      </div>
      <div className="mb-3">
        <label htmlFor="ageRequirement" className="form-label">Age Requirement:</label>
        <select
          id="ageRequirement"
          className="form-select"
          value={ageRequirement}
          onChange={(e) => setAgeRequirement(e.target.value)}
        >
          <option value="all">All Ages</option>
          <option value="16">16+</option>
          <option value="17">17+</option>
          <option value="18">18+</option>
          <option value="21">21+</option>
          <option value="custom">Custom Age</option>
        </select>

        {ageRequirement === 'custom' && (
          <input
            type="number"
            className="form-control mt-2"
            placeholder="Enter custom age"
            value={customAge}
            onChange={(e) => setCustomAge(e.target.value)} // Update custom age input value
            min="0"
          />
        )}
      </div>
      <div className="mb-3">
        <label htmlFor="facebookEventLink" className="form-label">Facebook Event Link:</label>
        <input
          type="url"
          className="form-control"
          id="facebookEventLink"
          value={facebookEventLink}
          onChange={(e) => setFacebookEventLink(e.target.value)}
        />
      </div>

      <div className="mb-3">
        <label htmlFor="ticketLink" className="form-label">Ticket Link:</label>
        <input
          type="url"
          className="form-control"
          id="ticketLink"
          value={ticketLink}
          onChange={(e) => setTicketLink(e.target.value)}
        />
      </div>

      {ticketLink && (
        <div className="mb-3">
          <label htmlFor="ticketPrice" className="form-label">Ticket Price:</label>
          <input
            type="text"
            className="form-control"
            id="ticketPrice"
            value={ticketPrice}
            onChange={(e) => setTicketPrice(e.target.value)}
            required
          />
        </div>
      )}

      <div className="mb-3">
        <label htmlFor="flyerUpload" className="form-label">Upload Flyer:</label>
        <input
          type="file"
          className="form-control"
          id="flyerUpload"
          onChange={handleFileChange}
          required={!editing}  // Flyer is required only when creating a new event, not during editing
        />
        {previewUrl && (
          <div className="mt-3">
            <img src={previewUrl} alt="Flyer Preview" className="img-thumbnail" style={{ maxWidth: '300px' }} />
          </div>
        )}
      </div>

      {error && <div className="alert alert-danger">{error}</div>}

      <button type="submit" className="btn btn-primary">
        {editing ? 'Edit Event' : 'Create Event'}
      </button>
      {editing && (
        <button
          type="button"
          className="btn btn-secondary ms-2"
          onClick={resetForm}
        >
          Cancel Edit
        </button>
      )}
    </form>
  );
}

export default EventForm;